import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="bg-yellow-400">
      <div className="flex flex-wrap items-center justify-between max-w-4xl mx-auto p-4 md:p-8">
        <Link className="flex items-center no-underline text-black" to="/">
          <svg
            className="fill-current h-8 mr-2 w-8"
            height="54"
            viewBox="0 0 54 54"
            width="54"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="scale(0.1)">
              <path d="M205.624 245.139h34.938c24.986 0 45.314-20.329 45.314-45.314 0-24.987-20.328-45.316-45.314-45.316h-34.938c-6.903 0-12.5 5.597-12.5 12.5v65.631c0 6.901 5.596 12.499 12.5 12.499zm12.5-65.632h22.438c11.201 0 20.314 9.114 20.314 20.316 0 11.201-9.113 20.313-20.314 20.313h-22.438v-40.629z" />
              <path d="M146.112 384.048h59.512c6.904 0 12.5-5.597 12.5-12.5V304.65h22.438c57.802 0 104.826-47.024 104.826-104.826 0-57.803-47.023-104.828-104.818-104.828l-94.451-.043h-.006a12.5 12.5 0 00-12.5 12.5v264.096c-.001 6.902 5.595 12.499 12.499 12.499zm12.5-264.09l81.951.037c44.017 0 79.825 35.811 79.825 79.828s-35.81 79.825-79.827 79.825h-34.938c-6.903 0-12.5 5.598-12.5 12.5v66.898h-34.512l.001-239.088z" />
              <path d="M466.5 0h-454C5.597 0 0 5.597 0 12.5v454c0 6.903 5.597 12.5 12.5 12.5h454c6.903 0 12.5-5.597 12.5-12.5v-454C479 5.597 473.403 0 466.5 0zM454 454H25V25h429v429z" />
            </g>
          </svg>
          <span className="font-bold text-xl tracking-tight">
            {site.siteMetadata.title}
          </span>
        </Link>

        <button
          className="block md:hidden border border-black flex items-center px-3 py-2 rounded text-black"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/`,
              title: `Home`,
            },
            {
              route: `/about`,
              title: `About`,
            },
            {
              route: `/videos`,
              title: `Videos`,
            },
            {
              route: `/records`,
              title: `Records`,
            },
            {
              route: `/recordslp`,
              title: `LP Records`,
            },
            {
              route: `/skateboards`,
              title: `Skateboards`,
            },
            {
              route: `/contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <Link
              className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-black"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
